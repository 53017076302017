import React, { useState, useEffect, useRef } from "react";
import * as userServices from "../../services/userServices.js";
import swal from "sweetalert";
import "./css/stripe.css";
import "./css/paymentcard.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentPaymentMethod } from "../../services/userServices";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link } from 'react-router-dom';
import { makeToast } from "helpers/common";

export const subscriptionFailSwalArgs = {
  title: "Please verify your payment method",
  icon: "error",
  text: "There was an issue with the payment information you input. Please try another payment method or card.",
};

export default function UpdateCheckout(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [cardLast4, setCardLast4] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [isCardEditView, setIsCardEditView] = useState(false);
  const [cardBrand, setCardBrand] = useState("");
  const [updateButton, setUpdateButton] = useState(false);

  useEffect(() => {

    getCurrentPaymentMethod()
      .then((response) => {
        let expMonth = String(response.expMonth);
        if (expMonth.length < 2) {
          expMonth = 0 + expMonth;
        }

        let expYear = String(response.expYear).slice(-2);

        setCardLast4(response.last4);
        setCardExpiry(expMonth + "/" + expYear);
        setCardBrand(response.brand);

      })
      .catch((err) => console.error(err));
  }, []);

  const btnPay = {
    margin: "0 auto",
    display: "block",
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontSize: "15px",

        "::placeholder": {
          color: "#CFD7E0",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const ChangeCardEditView = () => {
    setIsCardEditView(!isCardEditView);
  }

  const onUpdateCard = (e) => {
    setUpdateButton(true);
    userServices
      .getCreateCustomer()
      .then((response) => {
        let _data = {
          user_id: response.userId,
          stripe_id: response.stripeId,
        };
        handlePayment(response, _data);
      })
      .then((result) => {
        return result;
      });
  };

  const handlePayment = async (response, _data) => {
    const originalResponse = { ...response };
    toast.info("Processing", {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
    // Block native form submission.
    let id = response.stripeId;
    if (!stripe || !elements) {
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
    } else {
      let data = {
        customerId: id,
        paymentMethodId: paymentMethod.id
      };

      userServices
        .UpdatePaymentMethod(data)
        .then((response) => {
          if (response.success) {
            // handle an successful Update response
            onSuccessResponse()
          } else {
            // handle an unsuccessful Update response
            onErrorResponse()
          }
        })
        .catch((err) => {
          onErrorResponse();
        });
    }
  };



  const onErrorResponse = () => {
    swal(subscriptionFailSwalArgs);
    setUpdateButton(false);
  };

  const onSuccessResponse = (response) => {
    swal({
      title: "Your MyRep account's card has been updated",
      text: "If applicable, your next subscription will be paid with your updated card",
    });
    setTimeout(() => {
      swal.close();
      window.location.replace("/rep/settings");
    }, 3000);
  };

  const radio = {
    display: "block",
  };

  return (
    <React.Fragment>
      <div className="card bg-light">
        <div className="inline-form">
          <form style={{ maxWidth: "100%", padding: "1rem", margin: "0 auto" }}>
            <input type="hidden" name="token" />
            <h4>Account card</h4>

            {cardLast4 && cardExpiry && (
              <div>
                <div className={"credit-card selectable " + cardBrand}>
                  <div className="credit-card-last4">{cardLast4}</div>
                  <div className="credit-card-expiry">{cardExpiry}</div>
                  <i
                    className="fa fa-pencil-square-o editcard"
                    onClick={() => {
                      ChangeCardEditView();
                    }}
                  ></i>
                </div>
              </div>
            )}

            {!(cardLast4 && cardExpiry) && (
              <div>
                <p>No card on record. <Link to="/rep/settings/upgrade" style={{ fontWeight: "bold", textDecoration: "underline" }}>Upgrade your account</Link> to premier to enjoy access to many great features, including no appointment caps.</p>
              </div>
            )}

            {isCardEditView && (
              <div>
                <div className="group">
                  <label>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </label>
                </div>

                <button
                  className="btn btn--outline btn--white payBtn"
                  type="button"
                  style={btnPay}
                  disabled={updateButton}
                  onClick={onUpdateCard}

                >
                  Update
                </button>
                <div className="row justify-content-center">
                  <span className="press_once">
                    {" "}
                    * Please press once and wait for confirmation box. Do not
                    refresh.
                  </span>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
