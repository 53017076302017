import React from "react";

const RealTimeUpdatesIcon = () => {
  return (

    <svg width={135} height={135} viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6336 70.9009C23.8957 70.2756 24.3484 69.6323 24.7772 68.9771C25.0393 68.584 25.3907 68.3398 25.8552 68.6496C26.3258 68.9593 26.2245 69.3643 25.9565 69.7633C24.5985 71.7348 23.7408 73.8968 23.4669 76.2852C23.4133 76.7438 23.1631 77.0595 22.6628 76.988C22.1744 76.9166 22.0017 76.5592 22.0612 76.1006C22.2876 74.3197 22.7641 72.6103 23.6336 70.9069V70.9009Z" fill="#25AAE1" />
      <path d="M28.8631 67.0771C28.595 67.4345 28.2615 67.5656 27.8684 67.3273C27.517 67.1129 27.4098 66.7734 27.6182 66.4101C27.8386 66.011 28.196 65.9336 28.5891 66.154C28.9643 66.3684 28.9941 66.7019 28.8631 67.0712V67.0771Z" fill="#25AAE1" />
      <path d="M72.9081 92.4142C72.9081 93.3136 72.9081 94.07 72.9081 94.8324C72.8962 96.9289 71.7764 98.0487 69.662 98.0606C66.9044 98.0725 64.1467 98.0606 61.3831 98.0606C55.1054 98.0606 48.8218 98.0606 42.5441 98.0606C40.1081 98.0606 39.0717 97.0182 39.0717 94.5882C39.0717 90.4964 39.0955 86.4046 39.0539 82.3127C39.0479 81.5385 39.2802 81.0858 39.9532 80.7463C40.5727 80.4366 41.1385 80.0256 41.883 79.561V89.5732H43.0325C62.6339 89.5732 82.2293 89.5732 101.831 89.5732C103.481 89.5732 103.874 89.1979 103.874 87.5779C103.874 71.1511 103.874 54.7243 103.874 38.2975V37.4755H41.9128V38.6906C41.9128 46.5228 41.9009 54.355 41.9306 62.1872C41.9306 62.9794 41.8413 63.4797 40.936 63.6524C40.3344 63.7656 39.7805 64.117 39.0717 64.4207V63.301C39.0717 53.0268 39.0717 42.7526 39.0717 32.4784C39.0717 30.0543 40.1081 29.0358 42.562 29.0358C51.5378 29.0358 60.5135 29.0358 69.4893 29.0358C71.8658 29.0358 72.9081 30.0781 72.914 32.4546C72.914 33.1515 72.914 33.8424 72.914 34.6107H94.0283C94.0283 33.2884 94.0223 32.0257 94.0283 30.7631C94.0402 29.5242 94.5167 29.0418 95.7555 29.0358C97.9771 29.0298 100.193 29.0179 102.414 29.0358C104.839 29.0596 106.613 30.7928 106.667 33.2289C106.715 35.2599 106.679 37.2909 106.679 39.316C106.679 55.4569 106.679 71.5978 106.679 87.7387C106.679 90.2164 105.434 91.8901 103.26 92.3249C102.831 92.4083 102.379 92.4023 101.938 92.4023C92.6762 92.4023 83.4146 92.4023 74.1589 92.4023H72.92L72.9081 92.4142ZM54.6468 34.5571H62.9555V31.9424H54.6468V34.5571ZM51.8356 95.1778H60.1443V92.4678H51.8356V95.1778ZM49.0183 31.9066V34.6107H51.7045V31.9066H49.0183Z" fill="#25AAE1" />
      <path d="M47.5115 100.931C45.6353 105.16 43.7889 109.306 41.9128 113.534C34.3724 109.764 26.9095 106.03 19.381 102.266C20.0243 101.08 20.6199 99.9725 21.2274 98.8646C23.1274 95.3922 25.0154 91.9139 26.9333 88.4475C27.5468 87.3337 27.8505 86.1782 27.8326 84.9096C27.8029 82.1221 27.8207 79.3288 27.8267 76.5413C27.8267 74.004 28.9822 72.1815 31.2812 71.1153C34.7298 69.5191 38.1843 67.9408 41.6447 66.3684C43.9736 65.3082 46.35 66.0467 47.4459 68.1492C48.5478 70.2577 47.7795 72.6044 45.6055 73.9326C42.6871 75.7194 39.7865 77.536 36.9097 79.3883C36.588 79.5968 36.3022 80.1209 36.3022 80.5021C36.2605 85.797 36.2605 91.092 36.2843 96.3869C36.2962 99.1148 38.0294 100.818 40.7632 100.842C42.8896 100.86 45.0099 100.854 47.1362 100.86C47.1958 100.86 47.2613 100.884 47.5174 100.943L47.5115 100.931Z" fill="#25AAE1" />
      <path d="M80.0137 68.5007H96.7859V78.2626H80.0137V68.5007Z" fill="#25AAE1" />
      <path d="M65.8144 48.8397V58.5243H49.0243V48.8397H65.8144Z" fill="#25AAE1" />
      <path d="M71.4608 43.1517H74.2244V83.9745C73.4739 83.9745 72.7234 84.0102 71.973 83.9507C71.7883 83.9388 71.5739 83.6529 71.4786 83.4444C71.3952 83.2598 71.4608 83.0096 71.4608 82.7892C71.4608 69.948 71.4608 57.1067 71.4608 44.2714V43.1576V43.1517Z" fill="#25AAE1" />
      <path d="M98.1916 55.8738V58.5421H78.5604V55.8738H98.1916Z" fill="#25AAE1" />
      <path d="M67.1962 79.6802H47.6306V76.994H67.1962V79.6802Z" fill="#25AAE1" />
      <path d="M98.1796 50.1202H85.6064V47.3983H98.1796V50.1202V50.1202Z" fill="#25AAE1" />
      <path d="M56.0405 71.2285V68.5007H67.1962V71.2285H56.0405Z" fill="#25AAE1" />
      <path d="M82.7177 47.4043V50.1203H78.5663V47.4043H82.7177Z" fill="#25AAE1" />
    </svg>
  )
}

export default RealTimeUpdatesIcon;
