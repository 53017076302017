import { UserContext } from "components/context/UserContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getImpersonationOptions, impersonateOffice } from "services/companyServices";
import Header from "./Header";
import { useHistory } from "react-router-dom";
import NotFound from "components/NotFound";

const ImpersonationOptions = () => {
  const { state, dispatch } = useContext(UserContext)!;
  const { currentUser, isLoading } = state;
  const history = useHistory();

  const [offices, setOffices] = useState<LooseObject[]>([]);

  const getOffices = async () => {
    if(currentUser.roleId !== 8) return;

    dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: true } });
    try {
      const res = await getImpersonationOptions();
      if (res.isSuccessful) setOffices(res.data);
      dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: false } });
    } catch (e) {
      dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: false } });
    }
  };

  const handleImpersonateOffice = async (officeId: number) => {
    const res = await impersonateOffice(officeId);

    if (res.isSuccessful) {
      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...res.item, isLoggedIn: true } },
      });

      history.push("/client/calendar");
    }
  };

  useEffect(() => {
    getOffices();
  }, []);

  if (currentUser.roleId !== 8) return <NotFound />;

  if (isLoading) return null;

  return (
    <>
      <Header />
      <Container>
        <div
          style={{
            overflowX: "hidden",
            width: "100%",
            maxWidth: "100vw",
            marginTop: 20,
          }}
        >
          <h3>Select Office to Access</h3>
          <button className="btn mx-2" onClick={getOffices}>
            Refresh
          </button>

          {offices.length === 0 && <div>You haven't been delegated to any office yet.</div>}

          <Row noGutters>
            {offices.map((ofc) => (
              <Col sm={12} md={6} key={ofc.id}>
                <OfficeCard ofc={ofc} onImpersonate={() => handleImpersonateOffice(ofc.id)} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default ImpersonationOptions;

const OfficeCard: FC<{ ofc: LooseObject; onImpersonate: () => void }> = ({ ofc, onImpersonate }) => {
  const [showImg, setShowImg] = useState(true);
  const hideImage = () => setShowImg(false);

  return (
    <div className="d-flex align-items-center m-2">
      {showImg && <img style={{ maxWidth: 150, maxHeight: 150 }} src={ofc.fileUrl} alt={ofc.name} onError={hideImage} />}

      <div className="ml-4">
        <h3 className="font-weight-bold">{ofc.name}</h3>
        <p className="text-dark m-0">
          Address:&nbsp;
          <span className="text-capitalize text-secondary">
            {ofc.lineOne}, {ofc.city}
          </span>
        </p>
        <p className="text-dark m-0">
          Phone:&nbsp;
          <span className="text-secondary">{ofc.phone}</span>
        </p>
        <p className="text-dark m-0">
          Email:&nbsp;
          <span className="text-secondary">{ofc.email}</span>
        </p>
        <button onClick={onImpersonate} className="Button mt-2">
          Access
        </button>
      </div>
    </div>
  );
};
