import React from 'react';
import '../../sass/zeplin/TopLogo.scss';
import Logo from './Logo';

const TopLogo = ({ show }) => {
    return (
        <div className={ 'TopLogo' + ( show  ? ' --show' : '' )}>
            <Logo />
        </div>
    );
}

export default TopLogo;
