import React, { Component } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import "./../css/header.css";
import {Link} from "react-router-dom";

// require("history").createBrowserHistory;

class Header extends Component {
  render() {

    return (
      <div style={{ position: "sticky", top: 0, backgroundColor: "#FFF", zIndex: 1 }}>
        <nav className="navbar navbariL navbar-expand-lg fixed-top navbar-light" style={{ top: "10px", padding: "1rem", position: "sticky", backgroundColor: "#FFF" }}>
          <img
            alt="MyRep"
            className="logo myreplogo large-logo"
            src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
          />
          <div className="navbaritems ml-auto">
            <ul className="nav-menu navbarlinks" style={{ display: "grid", gridTemplateColumns: "repeat(2, 150px)", columnGap: "1rem", textAlign: "center" }}>
              <Link to="/register">
                Create Account
              </Link>
              <Link data-cy='login' to="/login">
                Login
              </Link>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
