import moment from "moment";
import toast from "react-hot-toast";

const DATE_FORMAT: string = "M/D/YY";
const TIME_FORMAT: string = "h:mma";
const WEEK_DAY: string = "dddd";

const getDisplayDate = (date: string) => {
  return moment(date).format("M/D/YY");
};

const makeToast = (message: string, type: string, time: number = 3000) => {
  const t = type === "success" ? toast.success : type === "error" ? toast.error : toast;
  t(message, {
    duration: time,
    position: "top-center",
  });
};

export { DATE_FORMAT, WEEK_DAY, getDisplayDate, TIME_FORMAT, makeToast };
