import React, { useState } from "react";
import Header from "./Header";
import Card from "./common/Card";
import { Link, useHistory } from "react-router-dom";
import MainFooter from "./layout/MainFooter";
import "../sass/zeplin/Register.scss";

const Register = () => {
  const history = useHistory();

  return (
    <div className="Register">
      <div className="container">
        <div className="logoContainer">
          <img
            className="Logo"
            alt="MyRep"
            src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="promptContainer"
          >
            <p className="prompt" style={{ margin: 0 }}>
              Select your account type:
            </p>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
              gap: "1rem",
            }}
          >
            <Card
              style={{ display: "flex", alignItems: "center", width: "auto" }}
              onClick={() => {
                history.push("/rep/basic");
              }}
              isClickable
            >
              <h2>Representative</h2>
            </Card>
            <Card
              style={{ display: "flex", alignItems: "center", width: "auto" }}
              onClick={() => {
                history.push("/client");
              }}
              isClickable
            >
              <h2>Medical Practice</h2>
            </Card>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 42 }}>
        <MainFooter />
      </div>
    </div>
  );
};

export default Register;
