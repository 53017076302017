import React, { Dispatch, FC, createContext, useReducer } from "react";
type Action = {
  type:
    | "SET_OFFICES"
    | "SET_OFFICES_PREFERENCES"
    | "SET_OFFICES_DIET_DATA"
    | "SET_OFFICES_I_FOLLOW"
    | "SET_BOOKED_APPOINTMENTS"
    | "FETCH_INITIATE"
    | "FETCH_COMPLETE"
    | "SET_APPROVALS"
    | "REFETCH_BOOKED_APPOINTMENTS";
  payload: LooseObject;
};

interface Data {
  offices: LooseObject[];
  officesPreferences: LooseObject[];
  officesDietData: LooseObject[];
  officesIFollow: LooseObject[];
  bookedAppointments: LooseObject[];
  refetchBookedAppointments: boolean;
  isLoading: boolean;
  approvals: LooseObject[];
}

interface ContextType {
  data: Data;
  dispatchAction: Dispatch<Action>;
}

const initialData: Data = {
  offices: [],
  officesPreferences: [],
  officesDietData: [],
  officesIFollow: [],
  bookedAppointments: [],
  refetchBookedAppointments: false,
  isLoading: false,
  approvals: [],
};

export const RepDataContext = createContext<ContextType | undefined>(undefined);

const reducer = (data: Data, action: Action) => {
  switch (action.type) {
    case "SET_OFFICES":
      return { ...data, offices: action.payload.offices };
    case "SET_OFFICES_PREFERENCES":
      return { ...data, officesPreferences: action.payload.officesPreferences };
    case "SET_OFFICES_DIET_DATA":
      return { ...data, officesDietData: action.payload.officesDietData };
    case "SET_OFFICES_I_FOLLOW":
      return { ...data, officesIFollow: action.payload.officesIFollow };
    case "SET_BOOKED_APPOINTMENTS":
      return { ...data, bookedAppointments: action.payload.bookedAppointments, refetchBookedAppointments: false };
    case "SET_APPROVALS": {
      return { ...data, approvals: action.payload.approvals };
    }
    case "REFETCH_BOOKED_APPOINTMENTS":
      return { ...data, refetchBookedAppointments: true };

    default:
      return data;
  }
};

export const RepDataContextProvider: FC<{ children: any }> = ({ children }) => {
  const [data, dispatchAction] = useReducer(reducer, initialData);
  return <RepDataContext.Provider value={{ data, dispatchAction }}>{children}</RepDataContext.Provider>;
};
