import { v2Request } from "../helpers/request";

let create = (payload) => {
  return v2Request("/products/create",
    { method: "POST", body: JSON.stringify((payload)) }
  )
};

let edit = (payload) => {
  return v2Request("/products/edit", {
    method: "PUT",
    body: JSON.stringify(payload)
  })
};

export { create, edit };
