import React, { useEffect, useReducer } from "react";
import { Form } from "reactstrap";
import { Formik, Field, FormikProps } from "formik";
import * as passwordService from "../services/emailService";
import validationSchema from "./pages/forgotPasswordValidationSchema";
import swal from "sweetalert";
import TextInput from "./common/TextInput";
import Logo from "./common/Logo";

interface PasswordState {
  email: string;
}

type Action = { type: "SET_EMAIL"; payload: string } | { type: "RESET_FORM" };

const passwordReducer = (state: PasswordState, action: Action): PasswordState => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "RESET_FORM":
      return { email: "" };
    default:
      return state;
  }
};

const Password: React.FC = () => {
  const [state, dispatch] = useReducer(passwordReducer, { email: "" });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch({ type: "RESET_FORM" });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleSubmit = (values: { email: string }) => {
    passwordService
      .forgotPass(values.email)
      .then((res) => {
        if (res.success) return onSuccess();

        const error = res.error || "Error";
        throw new Error(error);
      })
      .catch(onFailure);
  };

  const onSuccess = () => {
    swal({
      title: "Email Sent",
      text: "Please follow the link in the email to reset your password.",
      icon: "success",
    });
  };

  const onFailure = (error: Error) => {
    swal({
      icon: "error",
      text: error.message,
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div>
        <Logo className="Logo" style={{ marginLeft: 0 }} />
        <h2 style={{ marginTop: 32 }}>Forgot your password?</h2>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={state}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<{ email: string }>) => {
            const { values, touched, errors, handleSubmit, isValid, isSubmitting } = props;
            return (
              <div className="reset-password-box">
                <div className="col-md-8 p-0">
                  <Form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: 480 }}>
                    <p>Please verify your email</p>
                    <Field
                      as={TextInput}
                      type="email"
                      name="email"
                      value={values.email}
                      label="Enter email"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        dispatch({ type: "SET_EMAIL", payload: e.target.value })
                      }
                    />
                    {errors.email && touched.email && (
                      <span style={{ marginTop: 8, display: "block" }}>{errors.email}</span>
                    )}
                    <button
                      className="Button"
                      id="submit"
                      type="submit"
                      name="submit"
                      disabled={!isValid || isSubmitting}
                      style={{ borderRadius: 5, width: 100, marginTop: 24 }}
                    >
                      Send
                    </button>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Password;
