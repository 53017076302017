import * as Yup from "yup";

const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "at least 8 chars")
    .matches(/[a-z]/, "at least one lowercase character")
    .matches(/[A-Z]/, "at least one uppercase character")
    .matches(
      /[a-zA-Z]+[^a-zA-Z\s]+/,
      "at least 1 number or special char (@,!,#, etc)."
    ),
  passwordConfirm: Yup.string()
    .required("Confirm new password")
    .oneOf([Yup.ref("password"), null], "Passwords does not match!")
});
export default resetPasswordValidationSchema;