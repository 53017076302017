import AppRouter from "AppRouter";
import Loader from "components/common/Loader";
import LoadingSpinner from "components/common/LoadingSpinner";
import useLogout from "components/common/useLogout";
import { UserContext } from "components/context/UserContext";
import { getIoHost } from "helpers/signalr";
import React, { useContext, useEffect, Suspense, useState } from "react";
import { Toaster } from "react-hot-toast";
import { ToastContainer, toast } from "react-toastify";
import {
  getAppStatus,
  getConfig,
  getCurrent,
  getFeatureFlags,
  getNewAnnouncements,
  getReleaseDates,
  getUnreadChats,
} from "services/userServices";
import { Socket, io } from "socket.io-client";

let socket: Socket;
const ERR_BLACKLISTED = "Blacklisted";

const Main = () => {
  const [err, setErr] = useState("");
  const { dispatch, state } = useContext(UserContext)!;
  const logout = useLogout();

  const getData = async () => {
    try {
      // const status = await getAppStatus();
      // if (status.isBlacklisted) {
      //   setErr(ERR_BLACKLISTED);
      //   throw Error(ERR_BLACKLISTED);
      // }

      const resp = await getCurrent();
      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...resp.item, isLoggedIn: true } },
      });

      if (resp.error) {
        throw Error("Not logged In, Skipping Queued Calls.");
      }

      const config = (await getConfig()) || [];
      dispatch({ type: "UPDATE_CONFIG", payload: { config } });
      // const releaseDates = (await getReleaseDates()) || []; // NOTE - REMOVED FOR RELEASE DATE PERFORMANCE REASONS
      // dispatch({ type: "UPDATE_RELEASE_DATES", payload: { releaseDates } });
      dispatch({ type: "UPDATE_RELEASE_DATES", payload: { releaseDates: [] } });

      const ff: LooseObject[] = [];
      let activeFeatures = ff.filter((f: LooseObject) => f.value === 1);
      activeFeatures = activeFeatures.map((el: LooseObject) => el.name);

      if (activeFeatures.length > 0) {
        dispatch({
          type: "SET_ACTIVE_FEATURES",
          payload: { features: activeFeatures },
        });
      }

      dispatch({ type: "INITIAL_DATA_FETCHED", payload: {} });
    } catch (e) {
      dispatch({ type: "INITIAL_DATA_FETCHED", payload: {} });
    }
  };

  useEffect(() => {
    getData();

    return () => {
      state.socketHandler?.close();
    };
  }, []);

  useEffect(() => {
    if (state.currentUser?.id) {
      setupWS();
    }
  }, [state.currentUser]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("WS Connection Successful");
      });

      socket.on("notification", (data) => {
        if (data.newChatMessage)
          dispatch({
            type: "INC_UNREAD_CHATS",
            payload: { unreadChatId: data.chatId },
          });

        if (data.newAnnouncement)
          dispatch({
            type: "INC_NEW_ANNOUNCEMENTS",
            payload: { newAnnouncement: data.announcement },
          });

        if (data.forceLogout) {
          logout();
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (state.activeFeatures.length > 0) {
      if (state.activeFeatures.includes("IN_APP_MESSAGING")) {
        getMessagingData();
      }
    }
  }, [state.activeFeatures]);

  const setupWS = () => {
    const EndPoint = getIoHost();
    socket = io(EndPoint, { query: { email: state.currentUser.email }, transports: ["websocket"] });
    dispatch({
      type: "SET_SOCKET_HANDLER",
      payload: { socketHandler: socket },
    });
  };

  const getMessagingData = async () => {
    try {
      const resp = await getUnreadChats();
      dispatch({
        type: "SET_UNREAD_CHATS",
        payload: { unreadChats: resp.unreadChats },
      });

      if ([3, 5].includes(state.currentUser.roleId)) {
        const resp = await getNewAnnouncements();
        dispatch({
          type: "SET_NEW_ANNOUNCEMENTS",
          payload: { newAnnouncements: resp.data },
        });
      }
    } catch (e: any) {
      toast(e.message?.toString(), {
        autoClose: 2000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    }
  };

  const FallbackLoader = () => (
    <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
      <LoadingSpinner variant="alt" />
    </div>
  );

  if (err === ERR_BLACKLISTED) {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span style={{ fontSize: "50px" }}>&#128565;</span>
          <p className="text-danger">
            <b className="text-danger">403</b>. Access denied.
          </p>
        </div>
      </div>
    );
  }

  if (state.initialDataFetching) {
    return <FallbackLoader />;
  }

  return (
    <main
      style={{
        overflowX: "hidden",
        overflowY: state.isLoading ? "hidden" : "auto",
      }}
    >
      <Loader show={state.isLoading} />
      <ToastContainer className="custom-toast" />
      <Toaster />
      <Suspense fallback={<FallbackLoader />}>
        <AppRouter />
      </Suspense>
    </main>
  );
};

export default Main;
