import React, { useReducer } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import MainFooter from "../layout/MainFooter";
import * as validationHelper from "../../helpers/validation.helper";
import BasicForm from "../wizard/BasicForm";
import LoadingOverlay from "components/common/LoadingOverlay";
import TopLogo from "../common/TopLogo";
import { registerRep } from "../../services/userServices";
import { deepCopy } from "components/common/utilities";
import { PRIVACY_LINK, TERMS_LINK } from "components/common/constants/links";

const errorMap = {
  email: 'Email address is invalid.',
  password: 'Password must be at least 6 characters long and must contain at least 1 uppercase letter, 1 lowercase letter and 1 number.',
  confirmPassword: 'Confirm password must be same as password.'
};

const initialState = {
  formData: {
    email: {
      originalValue: "",
      value: "",
      valid: false,
      validation: {
        required: true,
        email: true,
      },
      touched: false,
    },
    password: {
      originalValue: "",
      value: "",
      valid: false,
      validation: {
        required: true,
        password: true,
      },
      touched: false,
    },
    confirmPassword: {
      originalValue: "",
      value: "",
      valid: false,
      validation: {
        required: true,
        password: true,
      },
      touched: false,
    },
  },
  showTermsModal: false,
  agreeTerms: false,
  showPrivacyModal: false,
  agreePrivacy: false,
  isLoading: false,
};

const updateFormData = (state, data) => {
  const value = data.type === "checkbox" ? data.checked : data.value;
  const name = data.name;
  const item = deepCopy(state.formData[name]);
  item.value = value;
  item.touched = true;
  item.valid = validationHelper.validate(item.value, item.validation);
  const newData = { ...state.formData, [name]: item };
  return newData;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: true };

    case "UNSET_LOADING":
      return { ...state, isLoading: false };

    case "CLOSE_MODAL":
      return { ...state, showPrivacyModal: false, showTermsModal: false };

    case "SHOW_PRIVACY":
      return { ...state, showPrivacyModal: true };

    case "SHOW_TERMS":
      return { ...state, showTermsModal: true };

    case "UPDATE_AGREE_PRIVACY":
      return { ...state, agreePrivacy: !state.agreePrivacy };

    case "UPDATE_AGREE_TERMS":
      return { ...state, agreeTerms: !state.agreeTerms };

    case "UPDATE_FORM_DATA": {
      return {
        ...state,
        formData: updateFormData(state, action.payload.data),
      };
    }

    default:
      return state;
  }
};

const RegisterBasic = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory()

  const handleCloseModal = () => dispatch({ type: "CLOSE_MODAL" });
  const showPrivacyModal = () => dispatch({ type: "SHOW_PRIVACY" });
  const showTermsModal = () => dispatch({ type: "SHOW_TERMS" });
  const showLoader = () => dispatch({ type: "SET_LOADING" });
  const hideLoader = () => dispatch({ type: "UNSET_LOADING" });
  const onChange = (event) => dispatch({ type: "UPDATE_FORM_DATA", payload: { data: event.target } });
  const onAgreeTerms = () => dispatch({ type: "UPDATE_AGREE_TERMS" });
  const onAgreePrivacy = () => dispatch({ type: "UPDATE_AGREE_PRIVACY" });

  const passwordsDontMatch =
    state.formData.password.value !== "" &&
    state.formData.confirmPassword.value !== "" &&
    state.formData.password.value !== state.formData.confirmPassword.value;

  const validateForm = () => {
    const errors = []
    for (let key in state.formData) {
      if (!state.formData[key].valid) {
        errors.push(errorMap[key]);
      }
      if (passwordsDontMatch) {
        errors.push(errorMap['confirmPassword']);
      }
    }

    if (errors.length > 0) {
      swal("Error", errors[0], "error");
      return false;
    }

    if (!state.agreePrivacy || !state.agreeTerms) {
      swal("Error", "You must agree to terms and privacy", "error");
      return false;
    }

    return true
  };

  const handleSubmitForm = async () => {
    const isFormValidated = validateForm()

    if (isFormValidated) {
      try {
        const payload = {
          Email: (state.formData.email.value).trim(),
          Password: state.formData.password.value,
          ConfirmPassword: state.formData.confirmPassword.value,
        };
        showLoader();
        const res = await registerRep(payload)
        if (res.error) {
          swal("Error", res.error, "error");
          throw new Error(res.error)
        }
        hideLoader()
        history.replace("/check/email");
      }
      catch (e) {
        hideLoader()
        swal("Error", e, "error");
      }
    }
  };

  return (
    <React.Fragment>
      {
        state.isLoading && <LoadingOverlay />
      }
      <Modal
        show={state.showTermsModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Terms</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <embed
            src={TERMS_LINK}
            style={{
              width: 700,
              height: 700,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="Button" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={state.showPrivacyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Privacy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <embed
            src={PRIVACY_LINK}
            style={{
              width: 700,
              height: 700,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="Button" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div style={{ marginBottom: 42 }}>
        <TopLogo show={true} />
        <h2
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 32,
            marginTop: 40,
            marginBottom: 32,
          }}
        >
          Create your account
        </h2>
      </div>
      <BasicForm
        formData={state.formData}
        handleChange={onChange}
        handleSubmit={handleSubmitForm}
        handleTermsModal={showTermsModal}
        handlePrivacyModal={showPrivacyModal}
        agreeState={onAgreeTerms}
        agreePrivacy={onAgreePrivacy}
        isLoading={state.isLoading}
        errors={{ confirmPassword: passwordsDontMatch ? "Passwords don't match" : "" }}
      />
      <div style={{ marginTop: 42 }}>
        <MainFooter />
      </div>
    </React.Fragment>
  );
};

export default withRouter(RegisterBasic);
