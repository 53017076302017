import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "components/context/UserContext";
import { getProfile } from "services/profileServices";
import { isProfileComplete, isProfileConfirmedByAdmin } from "../../common/profile";
import MainFooter from "../../layout/MainFooter";
import Logo from "../../common/Logo";
import RepHeader from "../../common/RepHeader";
import OnboardingForm from "../../common/OnboardingForm";
import NotConfirmedMessage from "../accounts/NotConfirmedMessage";
import ServerIssueMessage from "../../common/ServerIssueMessage";
import TokenErrorMessage from "components/common/TokenErrorMessage";
import LoadingOverlay from "components/common/LoadingOverlay";

const RepDashboard = (props) => {
  const { state, dispatch } = useContext(UserContext);
  const location = useLocation();

  const [tokenError, setTokenError] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isProfileApprovedByAdmin = isProfileConfirmedByAdmin(state.profile);
  const isProfileCompletedByUser = isProfileComplete(state.profile);

  const showOnboarding = (!!state.profile.roleId && !isProfileCompletedByUser) && !["/login", "/register"].includes(location.pathname);
  const showNotConfirmed = isProfileCompletedByUser && !isProfileApprovedByAdmin;

  const showLoadingState = () => setIsLoading(true);
  const hideLoadingState = () => setIsLoading(false);

  const fetchProfileData = async () => {
    if (state.profile.id && isProfileApprovedByAdmin && isProfileCompletedByUser) return;
    try {
      showLoadingState();
      setFetchError(false);
      setTokenError(false);
      const res = await getProfile();
      if (res.code === 401) {
        setTokenError(true);
      }
      dispatch({ type: "SET_PROFILE", payload: { profile: res?.item } })
      hideLoadingState();
    } catch (ex) {
      setFetchError(true);
      hideLoadingState();
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Logo />
        <LoadingOverlay />
      </div>
    );

  if (tokenError) return <TokenErrorMessage />;
  if (fetchError) return <ServerIssueMessage />;

  if (!isLoading && showOnboarding)
    return <OnboardingForm preRedirect={() => fetchProfileData()} onSubmit={() => { }} isRep={true} />;

  if (showNotConfirmed) return <NotConfirmedMessage />;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        width: "98vw",
      }}
    >
      <div>
        <RepHeader />
        {props.children}
      </div>

      <MainFooter />
    </div>
  );
};

export default RepDashboard;
