import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "components/context/UserContext";
import { Link, useLocation } from "react-router-dom";
import allRoutes from "routes";

const NotFound = () => {
  const { state } = useContext(UserContext)!;
  const { currentUser } = state;

  const location = useLocation();

  const [processing, setProcessing] = useState(true);
  const [redirectPage, setRedirectPage] = useState("/");

  const [error, setError] = useState({
    message: "Not Found",
    explanation:
      "The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.",
  });

  useEffect(() => {
    const matchedRoute = allRoutes.find((r) => r.path === location.pathname);
    if (matchedRoute) {
      if (currentUser.roles.includes("Client")) {
        setRedirectPage("/client/calendar");
      }
      if (currentUser.roles.includes("Basic") || currentUser.roles.includes("Premier")) {
        setRedirectPage("/rep/appointments");
      }

      setError({
        message: "Not Allowed",
        explanation: `Sorry you're not allowed to access this page with your current permissions. This page requires you to be a ${matchedRoute.roles
          .map((r) => r + " user")
          .join(" or a ")} and your current permissions are as a ${currentUser.roles
          .map((r: any) => r + " user")
          .join(" and a ")}`,
      });

      if (currentUser.roles.includes("Delegated User")) {
        if (matchedRoute.roles.includes("Client")) {
          setError({
            message: "Not Allowed",
            explanation: `Sorry you're not allowed to access this page with your current permissions. This page requires Client Access`,
          });
        } else {
          setError({
            message: "Not Allowed",
            explanation: `Sorry you're not allowed to access this page with your current permissions. This page requires you to be a ${matchedRoute.roles
              .map((r) => r + " user")
              .join(" or a ")}`,
          });
        }
      }
    }

    setProcessing(false);
  }, []);

  if (processing) {
    return null;
  }

  return (
    <div>
      <div className="loader-wrapper">
        <div className="loader bg-white">
          <div className="line" />
          <div className="line" />
        </div>
      </div>

      <div className="page-wrapper">
        <div className="error-wrapper">
          <div className="container">
            <div className="col-md-8 offset-md-2" style={{ marginTop: 42 }}>
              <h3>{error.message}</h3>
              <p className="sub-content">{error.explanation}</p>
              <Link to={redirectPage}>Return to MyRep</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
