import React, { useState } from "react";
import "../App.css";
import Header from "./Header";
import { Link } from "react-router-dom";
import "../css/animate-3.7.0.css";
import "../css/font-awesome-4.7.0.min.css";
import "../css/bootstrap-4.1.3.min.css";
import "../css/owl-carousel.min.css";
import "../css/jquery.datetimepicker.min.css";
import "../css/linearicons.css";
import "../css/style.css";
import "../sass/zeplin/Home.scss";
import MainFooter from "../components/layout/MainFooter";
import MiniLandingPageIcon from "./MiniLandingPageIcon";
import TimeSavingAutomationsIcon from "./TimeSavingAutomationsIcon";
import IntuitiveAccessIcon from "./IntuitiveAccessIcon";
import CustomerTailoredSchedulesIcon from "./CustomerTailoredSchedulesIcon";
import TransparentSchedulingIcon from "./TransparentSchedulingIcon";
import ExpeditedAppointmentsIcon from "./ExpeditedAppointmentsIcon";
import TerritorySupportIcon from "./TerritorySupportIcon";
import RealTimeUpdatesIcon from "./RealTimeUpdatesIcon";
import FlexibleSchedulesIcon from "./FlexibleSchedulesIcon";

const testimonials = [
  { text: "MyRep gives us back valuable time that we used to spend booking and rescheduling Rep appointments.  This way we have more time for our patients. Those small segments of time make a big deal. Our office uses their tools to explain our schedule and guidelines so that our meetings are clear and fair for all parties concerned.  I also like that we are notified if we have a Rep who tries to book outside our instructions.  The MyRep team is professional, easy and fun to work with. We highly recommend them.", author: "Rhonda M, Oncology Practice Manager" },
  { text: "I love working with My Rep as they really cater to the needs of both the customer AND the Reps.  There have been several suggestions passed on where they implement the changes based on the feedback. They make a tedious process less painful for all!  Thank you My Rep!", author: "Miranda, Oncology sales representative" },
  { text: "As a new rep in oncology, I would hear that it was difficult to get appointments. When I started as a new rep, I had no counterparts and thought I would have to wait months or even a year to see my offices; however, with MyRep, that wasn't the case. I could book appointments very quickly just a few weeks out, which allowed me to make a quick impact in my territory. I love MyRep, and I wish all my offices used it!", author: "Olivia, Oncology sales representative" },
];

function Home() {
  const [visibleBenefitsContent, setVisibleBenefitsContent] = useState("practices");

  return (
    <React.Fragment>
      <Header />
      <div className="container"><section className="mainSection" >
        <div className="landingPageHeader">
          <div>
            <h2>
              Scheduling for medical practices and representatives
            </h2>

            <h3>MyRep makes connections between reps and physicians easy and impactful</h3>
            <Link to="/register" data-cy='get-started' className="template-btn">
              Get Started
            </Link>
          </div>
          <div className="calendarIconHolder">
            <MiniLandingPageIcon />
          </div>
        </div>
      </section>
      </div >
      <section
        className="featureArea"
        style={{
          backgroundColor: "#F9F9F9",
        }}
      ><div style={{ maxWidth: 950, margin: "0 auto", paddingTop: 75, paddingBottom: 75 }} className="container">
          <div className="benefitsHeader">
            <h2>Benefits for {visibleBenefitsContent === "practices" ? "Medical Practices" : "Representatives"}</h2>
            <button className="btn link" style={{ fontSize: "1rem" }} onClick={() => setVisibleBenefitsContent(visibleBenefitsContent === "practices" ? "reps" : "practices")}>Show benefits for {visibleBenefitsContent === "practices" ? "reps" : "practices"}</button>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "3rem",
              justifyContent: "center",
            }}
          >
            {visibleBenefitsContent === "practices" &&
              <div>
                <ul style={{ fontSize: "1.1rem", marginBottom: "2rem" }} className="benefitsList">
                  <li>
                    <TimeSavingAutomationsIcon />
                    <div>
                      <strong>Time-saving Automations</strong> It’s time to leave clunky, non-native calendars behind. MyRep’s automated appointment scheduling removes the need for offices to carry out confirmations, follow-up calls, and manual calendar entries.
                    </div>
                  </li>
                  <li>
                    <IntuitiveAccessIcon />
                    <div><strong>Intuitive Access</strong> Through MyRep’s intuitive user interface, medical practices can get started immediately without the hassle of learning yet another platform. For a firsthand look, be sure to request a free demo.</div></li>
                  <li>
                    <CustomerTailoredSchedulesIcon />
                    <div><strong>Custom Schedules</strong> MyRep enables medical practices to set boundaries around their schedules, aligning with office policies, and tailored to suit office needs.</div></li>
                  <li>
                    <TransparentSchedulingIcon />
                    <div><strong>Transparent Scheduling</strong> With easy-to-add meeting requests, MyRep ensures that both parties are on the same page thereby avoiding cancellations by automatically swapping out unavailable reps with qualified replacements. Representatives don't see your calendar or who is on it.</div></li>
                </ul>

              </div>
            }
            {visibleBenefitsContent === "reps" && <div>
              <ul style={{ fontSize: "1.1rem", marginBottom: "2rem" }} className="benefitsList">
                <li><ExpeditedAppointmentsIcon /><div><strong>Expedited Appointments</strong> While other platforms require reps to wait up to a year after joining for an appointment, MyRep connects reps with appointment opportunities within the first two months of signing up.</div></li>
                <li><TerritorySupportIcon /><div><strong>Territory Support</strong> Whether you’re currently out-of-area or managing a large territory, MyRep supports scheduling and meeting from anywhere, automating the process when you are unable to access your devices.</div></li>
                <li><RealTimeUpdatesIcon /><div><strong>Real-time Updates</strong> When new appointment requests or cancellations arise, MyRep Premier sends out real-time updates. In turn, reps can maximize their reach and establish invaluable relationships with physicians.</div></li>
                <li><FlexibleSchedulesIcon /><div><strong>Added Flexibility</strong> MyRep Premier empowers you to schedule unlimited appointments and even transfer appointments to qualified reps if your availability changes.</div></li>
              </ul>

            </div>}
          </div>
          <div className="row justify-content-center" style={{ marginBottom: "3rem" }}>
            <div className="col col-md-4" >
              {visibleBenefitsContent === "practices" &&
                <a
                  rel="noopener noreferrer"
                  className="text-center py-4 demo-btn w-100"
                  href="https://app.smartsheet.com/b/form/7059ea5fd4ca4aa89c50dd3fa48ae911"
                  target="_blank"
                >
                  Request a Demo
                </a>
              }

              {visibleBenefitsContent === "reps" && <a
                className="text-center py-4 demo-btn mt-5 w-100"
                href="/rep/basic"
              >
                Get started for free
              </a>}
            </div>
          </div>

          <h3 style={{ marginBottom: "2rem" }}>Testimonials</h3>
          {testimonials.map((quote) => (
            <figure className="testimonial" style={{ marginBottom: "3rem" }}>
              <blockquote>{quote.text}</blockquote>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <figcaption>{quote.author}</figcaption>
              </div>
            </figure>
          ))}
        </div ></section >

      {/* <section class="patient-area section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="section-top text-center">
                <h2>Patient are saying</h2>
                <p>
                  Green above he cattle god saw day multiply under fill in the
                  cattle fowl a all, living, tree word link available in the
                  service for subdue fruit.
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5">
              <div class="single-patient mb-4">
                <img src="./images/patient1.png" alt="" />
                <h3>daren jhonson</h3>
                <h5>hp specialist</h5>
                <p class="pt-3">
                  Elementum libero hac leo integer. Risus hac road parturient
                  feugiat. Litora cursus hendrerit bib elit Tempus inceptos
                  posuere metus.
                </p>
              </div>
              <div class="single-patient">
                <img src="./images/patient2.png" alt="" />
                <h3>black heiden</h3>
                <h5>hp specialist</h5>
                <p class="pt-3">
                  Elementum libero hac leo integer. Risus hac road parturient
                  feugiat. Litora cursus hendrerit bib elit Tempus inceptos
                  posuere metus.
                </p>
              </div>
            </div>
            <div class="col-lg-5 offset-lg-1 align-self-center">
              <div class="appointment-form text-center mt-5 mt-lg-0">
                <h3 class="mb-5">appointment now</h3>
                <form action="#">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Your Name"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Your Name'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Your Email"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Your Email'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      id="datepicker"
                      placeholder="Date"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Date'"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <textarea
                      name="message"
                      cols="20"
                      rows="7"
                      placeholder="Message"
                      onfocus="this.placeholder = ''"
                      onblur="this.placeholder = 'Message'"
                      required
                    ></textarea>
                  </div>
                  <a href="#" class="template-btn">
                    appointment now
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section class="specialist-area section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="section-top text-center">
                <h2>Our specialish</h2>
                <p>
                  Green above he cattle god saw day multiply under fill in the
                  cattle fowl a all, living, tree word link available in the
                  service for subdue fruit.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-doctor mb-4 mb-lg-0">
                <div class="doctor-img">
                  <img
                    src="assets/images/doctor1.jpg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
                <div class="content-area">
                  <div class="doctor-name text-center">
                    <h3>ethel davis</h3>
                    <h6>sr. faculty data science</h6>
                  </div>
                  <div class="doctor-text text-center">
                    <p>
                      If you are looking at blank cassettes on the web, you may
                      be very confused at the.
                    </p>
                    <ul class="doctor-icon">
                      <li>
                        <a>
                          <i class="fa fa-facebook"></i>
                          <i class="fa fa-twitter"></i>
                          <i class="fa fa-linkedin"></i>
                          <i class="fa fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <MainFooter />
    </React.Fragment >
  );
}

export default Home;
