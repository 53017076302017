import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Logo from "./common/Logo";
import { Form, Label, FormGroup, Button } from "reactstrap";
import { Formik, Field, FormikHelpers } from "formik";
import validationSchema from "../components/pages/resetPasswordValidationSchema";
import swal from "sweetalert";
import * as passwordService from "../services/emailService";

interface State {
  password: string;
  passwordConfirm: string;
}

const ResetPass: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const [formData, setFormData] = useState<State>({
    password: "",
    passwordConfirm: "",
  });

  useEffect(() => {
    if (token) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        token,
      }));
    }
  }, [token]);

  const handleSubmit = (
    values: State,
    helpers: FormikHelpers<State>
  ) => {
    passwordService.resetPass(values).then(
      (res) => {
        if (res.error) throw new Error(res.error);
        swal({
          icon: "success",
          title: "Success",
        });
        history.push("/login");
      }
    ).catch(
      (error) => {
        swal({
          icon: "warning",
          title: error.message,
        });
      }
    );
  };

  return (
    <div>
      <div className="col-8 justify-content-center">
        <div className="card auth-innerright">
          <Logo
            style={{ margin: "42px auto 0 auto", maxWidth: "50%", width: 168 }}
            className="Logo notMobile"
          />
          <div className="authentication-box" style={{ margin: "20px auto" }}>
            <h3>RESET YOUR PASSWORD</h3>
            <div className="card mt-4 p-4">
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={formData}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                  } = props;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup className="form-group">
                        <Label className="col-form-label">Create New Password</Label>
                        <Field
                          type="password"
                          name="password"
                          values={values?.password}
                          placeholder="New Password"
                          className="form-control"
                        />
                      </FormGroup>
                      {errors?.password && touched?.password && (
                        <span className="input-feedback">{errors?.password}</span>
                      )}
                      <FormGroup>
                        <Label className="col-form-label">Confirm New Password</Label>
                        <Field
                          type="password"
                          name="passwordConfirm"
                          values={values?.passwordConfirm}
                          placeholder="Confirm Password"
                          className="form-control"
                        />
                      </FormGroup>
                      {errors?.passwordConfirm && touched?.passwordConfirm && (
                        <span className="input-feedback">
                          {errors?.passwordConfirm}
                        </span>
                      )}

                      <div>
                        <Button
                          type="submit"
                          disabled={!isValid || isSubmitting}
                          className="Button"
                        >
                          Create
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;