import { v2Request } from "../helpers/request";

const confirmFood = (payload, apptId) => {
  return v2Request(`/offices/food/confirm/appt/${apptId}`, { method: "POST", body: JSON.stringify(payload) });
};

const requestFoodApptSeriesEvent = (payload) => {
  return v2Request(`/offices/food/confirm/series/event`, { method: "POST", body: JSON.stringify(payload) });
};

const confirmNoFood = (apptId) => {
  return v2Request(`/appointments/confirm/${apptId}`, { method: "PUT" });
};

const search = (food, location) => {
  return v2Request(`/offices/food/search?food=${food}&location=${location}`);
};

const getResturantsWithIds = (ids) => {
  return v2Request(`/offices/yelp/resturants`, { method: "POST", body: JSON.stringify(ids) });
};

const deleteFoodOption = (id) => {
  return v2Request(`/offices/food/${id}`, { method: "DELETE" });
};

export { confirmFood, search, confirmNoFood, deleteFoodOption, getResturantsWithIds, requestFoodApptSeriesEvent };
