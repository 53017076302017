import LoadingSpinner from "./LoadingSpinner";

const Loader = ({ show, height = "100vh" }) => {
  return show ? (
    <div
      style={{
        background: "rgba(10,10,10, 0.3)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height,
        zIndex: 10,
      }}
    >
      <div className="d-flex justify-content-center align-items-center h-100 text-white">
        <LoadingSpinner />
      </div>
    </div>
  ) : null;
};

export default Loader;
