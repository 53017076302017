import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import PasswordInput from "./common/PasswordInput";
import TextInput from "./common/TextInput";
import TopLogo from "./common/TopLogo";
import Logo from "./common/Logo";
import MainFooter from "./layout/MainFooter";
import { UserContext } from "./context/UserContext";
import { roleBasedRedirect } from "./common/utilities";
import * as validationHelper from "../helpers/validation.helper";
import * as userService from "../services/userServices";
import { setAuthToken } from "./common/authTokenManager";
import toast from "react-hot-toast";
import "../sass/zeplin/Login.scss";
import "../css/login.css";

const validationRules = {
  email: { required: true },
  password: { required: true },
};

export const repInitialPage = "/rep/appointments";

const Login = () => {
  const [formData, setFormData] = useState({
    email: { value: "" },
    password: { value: "" },
  });
  const [loginError, setLoginError] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useContext(UserContext);
  const { currentUser, isLoading } = state;

  useEffect(() => {
    if (currentUser && currentUser.roleName) {
      if (location.state && location.state.redirectTo) {
        return history.replace(location.state.redirectTo);
      }

      roleBasedRedirect(currentUser.roleName, history);
    }
  }, [currentUser]);

  const onCurrentUserSuccess = async (data) => {
    try {
      const config = (await userService.getConfig()) || [];
      const releaseDates = (await userService.getReleaseDates()) || [];
      dispatch({ type: "UPDATE_CONFIG", payload: { config } });
      dispatch({ type: "UPDATE_RELEASE_DATES", payload: { releaseDates } });

      const ff = (await userService.getFeatureFlags()) || [];
      let activeFeatures = ff.filter((f) => f.value === 1);
      activeFeatures = activeFeatures.map((el) => el.name);

      if (activeFeatures.length > 0) {
        dispatch({
          type: "SET_ACTIVE_FEATURES",
          payload: { features: activeFeatures },
        });
      }

      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...data.item, isLoggedIn: true } },
      });

      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
    } catch (e) {
      toast(e.message?.toString(), {
        autoClose: 2000,
        type: toast.TYPE.ERROR,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      dispatch({
        type: "SET_CURRENT_USER",
        payload: { currentUser: { ...data.item, isLoggedIn: true } },
      });
      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginError(null);
    const errors = [];

    // Frontend validation
    Object.keys(formData).forEach((field) => {
      if (!validationHelper.validate(formData[field].value, validationRules[field])) {
        errors.push(`The ${field} field is required`);
      }
    });

    if (errors.length > 0) {
      return setLoginError(errors.join(". "));
    }

    let item = {
      email: formData.email.value,
      password: formData.password.value,
    };

    dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: true } });

    userService
      .login(item)
      .then((res) => {
        if (res.item && res.item.token) {
          setAuthToken(res.item.token);
        }
        if (res.error) throw new Error(res.error);
        if (res.forceLogout) {
          dispatch({
            type: "SET_LOADING_STATE",
            payload: { isLoading: false },
          });

          return swal(
            "Only one device allowed per account at a time",
            "You have been logged out of your other devices for security reasons."
          ).then(() => {
            dispatch({
              type: "SET_LOADING_STATE",
              payload: { isLoading: true },
            });

            return userService.getCurrent();
          });
        } else {
          return userService.getCurrent();
        }
      })
      .then(onCurrentUserSuccess)
      .catch((err) => {
        dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: false } });
        return setLoginError(err.message);
      });
  };

  const onChange = (field) => (e) => {
    const newFormData = { ...formData };
    newFormData[field].value = e.target.value;
    setFormData(newFormData);
  };

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  };
  const inputStyles = { width: "100%" };

  if (state.currentUser && state.currentUser.id) {
    return null;
  }

  return (
    <div style={{ overflow: "hidden", maxHeight: "100vh" }}>
      <TopLogo />
      <div id="container" className="Login" style={containerStyles}>
        <Logo style={{ margin: "42px auto 0 auto", maxWidth: "50%", width: 168 }} className="Logo notMobile" />
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              marginBottom: 40,
              maxWidth: 168,
            }}
          ></div>
          <h2 style={{ fontSize: 32 }}>Log in to your account</h2>
        </div>
        <form method="post" onSubmit={handleSubmit} className="LoginForm" id="login_form">
          <input type="hidden" name="auth" value="1" />
          <input type="hidden" name="redirect" value="" />
          <TextInput
            style={{ ...inputStyles, marginBottom: 40 }}
            label="Email"
            type="text"
            name="email"
            value={formData.email.value}
            onChange={onChange("email")}
            data-cy="login-email"
          />
          <PasswordInput
            containerStyles={{ width: "100%" }}
            name="password"
            value={formData.password.value}
            label="Password"
            autocomplete="off"
            onChange={onChange("password")}
            data-cy="login-password"
          />
          <span className="errormsg"></span>

          <div style={{ width: "100%", marginBottom: "2rem" }}>
            <Link to="/password" className="Link" style={{ fontSize: ".75rem" }}>
              Forgot Password?
            </Link>
          </div>

          {loginError && (
            <div
              style={{
                marginBottom: "1rem",
                backgroundColor: "salmon",
                padding: "10px",
                borderRadius: 3,
                color: "rgba(255,255,255,0.95)",
              }}
            >
              {loginError}
            </div>
          )}

          <button
            className="Button"
            disabled={isLoading}
            style={{ margin: "0 auto 10px auto" }}
            type="submit"
            data-cy="login-btn"
          >
            <span className="btn__label">Login</span>
          </button>
          <Link className="Button ButtonGray" style={{ margin: "0 auto" }} to="/register">
            <span className="btn__label">Create Account</span>
          </Link>
        </form>
      </div>
      <div className="half" id="forgotpassdiv" style={{ display: "none" }}>
        <h3>Forgot password</h3>

        <form method="post" id="forgotpassword_form">
          <ul>
            <li>
              <div className="inp-text">
                <TextInput label="Email" type="text" name="email" value="" />
              </div>
            </li>
            <li>
              <a className="btn btn--blue" href="#" onClick="forgotpassword_form.submit();">
                <span className="btn__label">
                  <img className="lock" src="/images/icon-lock-white.png" />
                  Submit
                </span>
              </a>
            </li>
            <li>
              <p>
                <a href="#" onClick="jQuery('#logindiv').show(); jQuery('#forgotpassdiv').hide();">
                  Back to login
                </a>
              </p>
            </li>
          </ul>
        </form>
      </div>
      <MainFooter />
    </div>
  );
};

export default Login;
