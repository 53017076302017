import React from "react";
import NavHeader, { NavLink } from "./NavHeader";
import useLogout from "./useLogout";

import "../../sass/zeplin/ClinicHeader.scss";

import {
  AccountsIcon,
  CalendarIcon,
  ProfileIcon,
  BookedIcon,
  LogoutIcon,
  AnnouncementsIcon,
} from "./ClinicHeader";

const links = [
  { pathname: "/rep/accounts", Img: AccountsIcon, name: "Accounts" },
  { pathname: "/rep/appointments", Img: CalendarIcon, name: "Appointments" },
  { pathname: "/booked", Img: BookedIcon, name: "Booked" },
  { pathname: "/rep/settings", Img: ProfileIcon, name: "Profile" },
  // { pathname: "messages", Img: AnnouncementsIcon, name: "Announcements" }
];

const RepHeader = () => {
  const logOut = useLogout();
  return (
    <NavHeader
      links={links}
      renderLinks={() => {
        return (
          <NavLink
            Img={LogoutIcon}
            onClick={() => {
              return logOut();
            }}
            name="Log out"
          />
        );
      }}
    />
  );
};

export default RepHeader;
