export const Messages = {
  APPOINTMENT_SUCCESS: "<strong>Appointment {0} Successfully</strong>",
  APPOINTMENT_SUCCESS_TITLE: "Congratulations!",
  APPOINTMENT_SUCCESS_ICON: "success",
  APPOINTMENT_FAILED_ICON: "error",
  APPOINTMENT_FAILED: "<strong>Appointment {0} failed</strong>",
  APPOINTMENT_FAILED_TITLE: "Failed",
  APPOINTMENT_CREATED: "Created",
  APPOINTMENT_DELETED: "Deleted",
  APPOINTMENT_BOOKED: "Booked",
  APPOINTMENT_CONFIRMED: "Confirmed",
  APPOINTMENT_CANCELLED: "Cancelled",
  APPOINTMENT_TRANSFERRED: "Transferred",
  APPOINTMENT_EDITTED: "Edited",
  APPOINTMENT_REQUEST_FOODS:
    "<strong>Please select one or multiple resturant(s) from the list</strong>",
  APPOINTMENT_REQUEST_FOODS_TITLE: "Request Foods",
  APPOINTMENT_FOOD_OPTION_SENT: "Preferred food options sent",
  APPOINTMENT_FOOD_OPTION_SENT_EMAIL:
    "<strong>An e-mail with your food selections has been sent to this medical rep</strong>",
  APPOINTMENT_FOOD_OPTION_SENT_ERROR:
    "<strong>Error occurred while sending preferred food options</strong>",
  APPOINTMENT_CANCEL_TIME_TITLE: "Please contact office to cancel",
  APPOINTMENT_CANCEL_TIME_WARNING:
    "The office has specified it is too close to the appointment time to cancel through this system. Please contact the office directly to cancel.",
  APPOINTMENT_CANCEL_WARNING:
    "Are you sure you want to cancel this appointment?",
};

export const EditAppointmentFields = {
  APPOINTMENT_TYPE: "appointment_type",
  APPOINTMENT_DATE: "appointment_date",
  APPOINTMENT_END_DATE: "appointment_end_date",
  APPOINTMENT_TIME: "appointment_time",
  APPOINTMENT_REQUIREMENTS: "appointment_requirements",
  APPOINTMENT_MEETING_FORMAT: "appointment_meeting_format",
  APPOINTMENT_RECURRENCE_FREQUENCY: "appointment_recurrence_frequency",
  APPOINTMENT_BOOKED_BY: "appointment_booked_by",
};

export const EventType = {
  CREATE: "Create",
  EDIT: "Edit",
  CANCEL: "Cancel",
  DELETE: "Delete",
  CONFIRM: "Confirm",
  TRANSFER: "Transfer",
  BOOK: "Book",
};

export const ButtonText = {
  CONFIRMED: "Confirmed",
  CONFIRM: "Confirm",
  CANCEL: "Cancel",
  CANCELLING: "Cancelling",
};

export const RecurrenceFrequency = {
  EVERY_WEEKDAY: "EVERY_WEEKDAY",
  EVERY_WEEK_SAME_DAY: "EVERY_WEEK_ON_SAME_DAY",
  EVERY_MONTH_SAME_DAY: "EVERY_MONTH_ON_SAME_DAY",
  NO_RECURRENCE: "DOES_NOT_RECUR",
};

export const DATE_FORMAT = "YYYY-MM-DD"

export const appointmentTypes = [
  "",
  "Lunch",
  "Request Samples",
  "Coffee",
  "Breakfast",
  "Appointment",
  "Brunch",
  "Clinic Lunch",
  "Admin Lunch",
  "Provider Lunch",
  "Education In-Service",
  "Other",
];

