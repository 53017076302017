import { v2Request } from "../helpers/request";

const sendAppointment = (payload) => {
  return v2Request("/appointments", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const editAppointment = (payload) => {
  return v2Request("/appointments/editappt", {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

const getRequirementStatus = () => {
  return v2Request("/appointments/requirements/status");
};

const getRepStatusForAppt = (repId) => {
  return v2Request(`/appointments/rep-status/${repId}`);
};

const confirmation = (payload) => {
  return v2Request("/appointments/final", {
    body: JSON.stringify(payload),
    method: "POST",
  });
};

const isAppointmentCurrent = (payload) => {
  return v2Request("/appointments/is-current", {
    body: JSON.stringify(payload),
    method: "POST",
  });
};

const file = (payload) => {
  return v2Request("/files/appointment/link", {
    body: JSON.stringify(payload),
    method: "POST",
  });
};

const bookAppt = async (payload) => {
  return v2Request("/appointments/booking", {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

const transferAppt = (payload) => {
  return v2Request("/appointments/transfer", {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

const cancelApptRep = (apptId) => {
  return v2Request(`/appointments/cancel/${apptId}`)
};

const getAllCalendarAppointments = () => {
  return v2Request(`/appointments/calendar`);
};

const getClientUpcomingAppointments = (days) => {
  return v2Request(`/appointments?days=${days}`);
};

const getAppointmentsAvailable = () => {
  return v2Request(`/appointments/available`);
};

const getAppointment = (appointmentId) => {
  return v2Request(`/appointments/available/${appointmentId}`);
};

const getApptsClient = () => {
  return v2Request("/appointments/booked");
};

const files = () => {
  return v2Request("/files/offices/calendar");
};

const getDietaryData = () => {
  return v2Request("/appointments/diet");
};

const getBooked = () => {
  return v2Request("/appointments/booked");
};

const getRepBooked = () => {
  return v2Request("/appointments/booked");
};

const getBookedAppointmentDetails = (id) => {
  return v2Request(`/appointments/booked/${id}`);
};

const getAppointmentDetails = (id, isRecurring) => {
  return v2Request(`/appointments/${id}?isRecurring=${isRecurring}`);
};

const deleteEventById = (data) => {
  return v2Request(`/appointments/delete/${data.id}`, {
    body: JSON.stringify(data),
    method: "DELETE",
  });
};

const getVirtualOptions = () => {
  return v2Request("/appointments/virtual/options");
};

const unsubscribeAllNotifications = (websocketConnection) => {
  if (websocketConnection !== null) {
    websocketConnection.close();
  }
};

export {
  sendAppointment,
  editAppointment,
  getApptsClient,
  transferAppt,
  files,
  getDietaryData,
  bookAppt,
  file,
  confirmation,
  getAllCalendarAppointments,
  getClientUpcomingAppointments,
  getAppointment,
  getAppointmentsAvailable,
  cancelApptRep,
  getRepBooked,
  getBooked,
  deleteEventById,
  unsubscribeAllNotifications,
  getVirtualOptions,
  getRequirementStatus,
  getBookedAppointmentDetails,
  isAppointmentCurrent,
  getRepStatusForAppt,
  getAppointmentDetails
};
