import React, { useEffect } from "react";
import * as EmailService from "../../services/emailService";
//import loaderImg from "../../assets/images/newloader.gif";

const useQueryParams = () => {
  const params = new URLSearchParams(window ? window.location.search : {});

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop);
    },
  });
};

const Confirm = (props) => {
  const guid = props.match?.params?.token;

  const onConfirmSuccess = () => {
    props.history.push("/login");
  };

  const onConfirmFailure = (err) => {
    props.history.push("/error-404");
  };

  useEffect(() => {
    if (guid)
      EmailService.confirmAcct(...[guid])
        .then((res) => {
          if (res.success) {
            setTimeout(onConfirmSuccess, 5000);
          }
        })
        .catch(onConfirmFailure);
  }, [guid]);

  if (!guid) {
    return <div>No token found</div>;
  }

  return (
    <div>
      <div>
        <div style={{ margin: "0 auto", textAlign: "center" }}>
          <h1 style={{ borderBottom: 0 }}>Email Confirmed!</h1>
          <>
            <p>Thank you for signing up with MyRep.</p>
            <p>In a few seconds you will be redirected to the login page.</p>
            <p>
              <a href="/login">Click here</a> to go there now
            </p>
          </>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
