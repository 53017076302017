import React from "react";
import NavHeader, { NavLink } from "./NavHeader";
import { BsCalendarWeek, BsBookmarkCheckFill } from "react-icons/bs";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaUserClock, FaUsers } from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";
import { AiOutlineNotification } from "react-icons/ai";
import { MdSwitchAccount, MdWorkHistory } from "react-icons/md";
import useLogout from "./useLogout";
import "../../sass/zeplin/ClinicHeader.scss";

export const CalendarIcon = ({ stroke }) => {
  return <BsCalendarWeek fill={stroke} size={35} />;
};

export const AppointmentsIcon = ({ stroke }) => {
  return <FaUserClock fill={stroke} size={35} />;
};

export const BookedIcon = ({ stroke }) => {
  return <BsBookmarkCheckFill fill={stroke} size={35} />;
};

export const AccountsIcon = ({ stroke }) => {
  return <MdSwitchAccount fill={stroke} size={40} />;
};

export const RepsIcon = ({ stroke }) => {
  return <FaUsers fill={stroke} size={40} />;
};

export const ProfileIcon = ({ stroke }) => {
  return <RiUserSettingsLine fill={stroke} size={35} />;
};

export const History = ({ stroke }) => {
  return <MdWorkHistory fill={stroke} size={35} />;
};

export const AnnouncementsIcon = ({ stroke }) => {
  return <AiOutlineNotification fill={stroke} size={35} />;
};

export const LogoutIcon = () => {
  return <RiLogoutCircleRLine fill="#224984" size={35} />;
};

const ClinicHeader = () => {
  const logOut = useLogout();

  const links = [
    { pathname: "/client/calendar", Img: CalendarIcon, name: "Calendar" },
    { pathname: "/appointments", Img: AppointmentsIcon, name: "Appointments" },
    { pathname: "/client/reps", Img: RepsIcon, name: "Reps" },
    { pathname: "/profile", Img: ProfileIcon, name: "Profile" },
    { pathname: "/client/user-activity", Img: History, name: "Activity" }
    // { pathname: "/messages", Img: AnnouncementsIcon, name: "Announcements" },
  ];

  return (
    <NavHeader
      links={links}
      renderLinks={() => {
        return (
          <>
            <NavLink
              Img={LogoutIcon}
              onClick={() => {
                return logOut();
              }}
              name="Log out"
              data-cy="logout-btn"
            />
          </>
        );
      }}
    />
  );
};

export default ClinicHeader;
