import React, { Dispatch, FC, createContext, useReducer } from "react";

type Action = {
  type:
    | "SET_APPOINTMENT_REQUIREMENTS"
    | "SET_REPS"
    | "UPDATE_REP"
    | "FETCH_REQUEST"
    | "FETCH_COMPLETE"
    | "INITIAL_DATA_FETCHED"
    | "SET_RESTURANT_PREFERENCES"
    | "SET_AVAILABLE_PRACTICE_TYPES"
    | "SET_OFFICE_PRACTICE_TYPES"
    | "SET_DELEGATED_USERS"
    | "SET_DIETARY";
  payload: JObj;
};

interface Data {
  appointmentRequirements: JObj;
  reps: Array<JObj>;
  isLoading: boolean;
  initialDataFetched: boolean;
  virtualOptions: Array<string>;
  dietary: JObj;
  resturantPreferences: Array<JObj>;
  officePracticeTypes: Array<JObj>;
  availablePracticeTypes: Array<JObj>;
  delegatedUsers: Array<JObj>;
}

interface ContextType {
  data: Data;
  dispatchAction: Dispatch<Action>;
}

const initialData: Data = {
  appointmentRequirements: {},
  reps: [],
  isLoading: false,
  initialDataFetched: false,
  virtualOptions: ["Virtual only", "Virtual optional", "In person only"],
  dietary: {},
  resturantPreferences: [],
  officePracticeTypes: [],
  availablePracticeTypes: [],
  delegatedUsers: [],
};

export const ClientDataContext = createContext<ContextType | undefined>(undefined);

const reducer = (data: Data, action: Action) => {
  switch (action.type) {
    case "SET_APPOINTMENT_REQUIREMENTS":
      return { ...data, appointmentRequirements: action.payload.appointmentRequirements };
    case "SET_APPOINTMENT_REQUIREMENTS":
      return { ...data, appointmentRequirements: action.payload.appointmentRequirements };
    case "SET_REPS":
      return { ...data, reps: action.payload.reps };

    case "UPDATE_REP": {
      const { id: idToUpdate, changes } = action.payload;

      if (!idToUpdate) return data;

      const newReps = [...data.reps];
      const repIdxToUpdate = newReps.findIndex((a) => a.id === idToUpdate);

      if (repIdxToUpdate < 0) return data;

      newReps[repIdxToUpdate] = { ...newReps[repIdxToUpdate], ...changes };

      return { ...data, reps: newReps };
    }

    case "SET_RESTURANT_PREFERENCES":
      return { ...data, resturantPreferences: action.payload.resturantPreferences };
    case "SET_AVAILABLE_PRACTICE_TYPES":
      return { ...data, availablePracticeTypes: action.payload.availablePracticeTypes };
    case "SET_OFFICE_PRACTICE_TYPES":
      return { ...data, officePracticeTypes: action.payload.officePracticeTypes };
    case "SET_DIETARY":
      return { ...data, dietary: action.payload.dietary };
    case "SET_DELEGATED_USERS":
      return { ...data, delegatedUsers: action.payload.delegatedUsers };
    case "FETCH_REQUEST":
      return { ...data, isLoading: true };
    case "FETCH_COMPLETE":
      return { ...data, isLoading: false };
    case "INITIAL_DATA_FETCHED":
      return { ...data, initialDataFetched: true };
    default:
      return data;
  }
};

export const ClientDataContextProvider: FC<{ children: any }> = ({ children }) => {
  const [data, dispatchAction] = useReducer(reducer, initialData);
  return <ClientDataContext.Provider value={{ data, dispatchAction }}>{children}</ClientDataContext.Provider>;
};
