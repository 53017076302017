import axios from "axios";
import { v2Request } from "../helpers/request";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL, REACT_APP_V2_API_ENDPOINT } = process.env;
let endpoints = {};
endpoints.fileUpload = REACT_APP_API_URL + "/upload";
// endpoints.fileUpload =  "http://www.myrepapp.com/upload";
endpoints.fileUploadToDb = REACT_APP_API_URL + "/upload/db";
endpoints.files = REACT_APP_API_URL + "/files/paginate";
endpoints.fileTypes = REACT_APP_API_URL + "/files/types";
endpoints.complianceUpload = REACT_APP_API_URL + "/files/compliance";

let getPaginated = (pageIndex, pageSize) => {
  const config = {
    method: "GET",
    url: endpoints.files + `?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    withCredentials: true,
    crossDomain: true,
    header: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let uploadFile = (data) => {
  const config = {
    method: "POST",
    // withCredentials: true,
    crossDomain: true,
    referrerPolicy: "same-origin",
    credentials: "include",
    // headers: { "Content-Type": "multipart/form-data" },
    body: data,
  };
  return fetch(`${REACT_APP_V2_API_ENDPOINT}/files/upload`, config)
    .then((res) => res.json())
    .catch(helper.onGlobalError);
};
// let uploadFile = (data) => {
//   const config = {
//     method: "POST",
//     url: ``,
//     withCredentials: true,
//     crossDomain: true,
//     header: {
//       "Content-Type": "",
//     },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

const uploadCompliance = (data) => {
  // const config = {
  //   method: "POST",
  //   url: endpoints.complianceUpload,
  //   data,
  //   withCredentials: true,
  //   crossDomain: true,
  //   header: {
  //     "Content-Type": "application/json",
  //   },
  // };
  // return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
  return v2Request("/files/compliance", { method: "POST", body: JSON.stringify(data) });
};

let uploadToDb = (data) => {
  const config = {
    method: "POST",
    url: endpoints.fileUploadToDb,
    data: data,
    withCredentials: true,
    crossDomain: true,
    header: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getFileTypes = () => {
  const config = {
    method: "GET",
    url: endpoints.fileTypes,
    withCredentials: true,
    crossDomain: true,
    header: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getComplianceFiles = () => {
  return v2Request("/files/compliance");
};

let deleteFile = (fileId) => {
  return v2Request(`/files/${fileId}`, { method: "DELETE" })
};

// const deleteFile = (fileId) => {
//   const config = {
//     method: "DELETE",
//     url: `${REACT_APP_API_URL}/files/${fileId}`,
//     withCredentials: true,
//     crossDomain: true,
//     header: {
//       "Content-Type": "application/json",
//     },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

const getById = (userId) => {
  return v2Request(`/files/user/${userId}`)
};

export {
  getPaginated,
  uploadFile,
  uploadToDb,
  getFileTypes,
  uploadCompliance,
  getComplianceFiles,
  deleteFile,
  getById,
};
