import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "assets/images/logo/logo.png";
import { Button } from "react-bootstrap";
import { CgLogOff } from "react-icons/cg";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { logOut } from "services/userServices";

const Header = () => {
  const onSuccessLogout = () => {
    swal({
      icon: "success",
      title: "Goodbye",
      text: "See you next time!",
      timer: 1000,
      buttons: false,
    }).then(() => {
      window.location = "/login";
    });
  };

  const onErrorLogout = (error) => {
    toast.error(error.message, { autoClose: 1000, pauseOnHover: false });
  };

  const handleLogout = () => {
    logOut()
      .then(() => {
        onSuccessLogout();
      })
      .catch(() => {
        onErrorLogout();
      });
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img style={{ width: 150 }} src={Logo} alt="My Rep" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse className="justify-content-end">
          <Button
            onClick={handleLogout}
            variant="outline-secondary"
            size="sm"
            className="mx-1 ml-4"
          >
            <CgLogOff style={{ marginTop: -2 }} size={20} /> Logout
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
