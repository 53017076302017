import axios from "axios";
import { v2Request } from "../helpers/request";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL } = process.env;
let endpoint = REACT_APP_API_URL + "/location"; //dev
//const endpoint = "http://52.53.243.189/api/user"; //production

let create = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoint + "/create",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let edit = (payload, id) => {
  return v2Request(`/users/location`, { method: "PUT", body: JSON.stringify(payload) })
};

let selectRegistrationLocationById = (id) => {
  const config = {
    method: "GET",
    url: endpoint + "/registration/" + id,
    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export { create, selectRegistrationLocationById, edit };
