import swal from "sweetalert";
import { Messages } from "../components/common/constants/appointment.constants";
import { objectsHaveSameValues } from "components/common/utilities";

export const showSWALPopupForAppointmentEvents = (isSuccess, message) => {
  swal({
    icon: isSuccess ? Messages.APPOINTMENT_SUCCESS_ICON : Messages.APPOINTMENT_FAILED_ICON,
    title: isSuccess ? Messages.APPOINTMENT_SUCCESS_TITLE : Messages.APPOINTMENT_FAILED_TITLE,
    content: createAppointmentEventsPopupMessage(isSuccess, message),
  });
};

export const showSWALPopupWithContent = (icon, title, message) => {
  swal({
    icon,
    title,
    content: createPopupContent(message),
  });
};

export const createAppointmentEventsPopupMessage = (isSuccess, message) => {
  let content = document.createElement("div");
  content.innerHTML = isSuccess
    ? Messages.APPOINTMENT_SUCCESS.replace("{0}", message)
    : Messages.APPOINTMENT_FAILED.replace("{0}", message);
  return content;
};

export const createPopupContent = (message) => {
  let content = document.createElement("div");
  content.innerHTML = message;
  return content;
};

export function addToList(arr, item) {
  const found = arr.find((el) => el === item);
  if (found) return arr;
  const data = [...arr];
  data.push(item);
  return data;
}

export function removeFromList(arr, item) {
  const newArr = arr.filter((el) => el !== item);
  return newArr;
}

export function addToListById(arr, item) {
  const found = arr.find((el) => el.id === item.id);
  if (found) return arr;
  const data = [...arr];
  data.unshift(item);
  return data;
}

export function removeFromListById(arr, item) {
  const newArr = arr.filter((el) => el.id !== item.id);
  return newArr;
}

export function areArraysEqual(arr1, arr2, isArrayOfObjects = false) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (isArrayOfObjects) {
    for (let i = 0; i < arr1.length; i++) {
      if (!objectsHaveSameValues(arr1[i], arr2[i], true)) {
        return false;
      }
    }
  } else {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
  }

  return true;
}

export const getFormattedDate = (value) => {
  const date = new Date(value);

  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString(undefined, options);
};
