import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { ScrollContext } from "react-router-scroll-4";
import { BrowserRouter } from "react-router-dom";
import { logOut, getCurrent } from "./services/userServices.js";
import { Modal } from "react-bootstrap";
import { UserContextProvider } from "components/context/UserContext";
import { ClientDataContextProvider } from "components/context/ClientDataContext";
import { RepDataContextProvider } from "components/context/RepDataContext";
import Main from "./Main";
import "./services/serviceHelper.js";
import "./index.css";

const inactiveMinsBeforeWarning = 15;
const inactivityMillisBeforeWarning = 1000 * 60 * inactiveMinsBeforeWarning;
const inactiveMillsBeforeLogout = 1000 * 60 * 1; // 1 min
let timeoutId = null;

const Root = () => {
  const [showInactiveWarningModal, setShowInactiveWarningModal] = useState(false);
  useEffect(() => {
    workerTimers.setInterval(() => {
      if (localStorage.getItem("hidemodal") === "true") {
        setShowInactiveWarningModal(false);
        if (timeoutId) workerTimers.clearTimeout(timeoutId);
        workerTimers.setTimeout(() => {
          localStorage.removeItem("hidemodal");
        }, 1000);
      }
    }, 1000);
  }, []);

  const handleOnIdle = () => {
    getCurrent()
      .then((res) => {
        if (res.error) {
          return;
        }
        if (res.item && [2, 6, 7, 8].includes(res.item.roleId)) {
          return;
        }
        setShowInactiveWarningModal(true);
        const lastActiveTime = getLastActiveTime().getTime();
        timeoutId = workerTimers.setTimeout(() => {
          if (getLastActiveTime().getTime() > lastActiveTime) return; // If the user has been active
          logOut().then(() => {
            window.location.replace("/login");
          });
        }, inactiveMillsBeforeLogout);
      })
      .catch((err) => console.error(err));

    // look for a current user
    // if one exists, log them out
  };

  const { getLastActiveTime } = useIdleTimer({
    crossTab: true, // makes sure that if multiple tabs are opened, being active on any one tab means you are active on all tabs
    timeout: inactivityMillisBeforeWarning, // 15 mins
    timers: workerTimers,
    onIdle: handleOnIdle,
    handleOnActive: () => {
      if (timeoutId) workerTimers.clearTimeout(timeoutId);
    },
  });

  return (
    <UserContextProvider>
      <ClientDataContextProvider>
        <RepDataContextProvider>
          <div>
            <Modal
              show={showInactiveWarningModal}
              onHide={() => {
                setShowInactiveWarningModal(false);
              }}
              centered={true}
            >
              <Modal.Body>
                <h3>Your session is about to end.</h3>
                <p>
                  You've been inactive for a while. For your security, we'll automatically sign you out in approximately
                  one minute. You may choose "I'm still here" to continue or "Log me out" if you're done with your
                  session.
                </p>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <button
                    className="Button ButtonGray"
                    onClick={() => {
                      logOut().then(() => {
                        window.location.replace("/login");
                      });
                    }}
                  >
                    Log me out
                  </button>
                  <button
                    className="Button"
                    onClick={() => {
                      localStorage.setItem("hidemodal", "true");
                      // Clicking this button (actually moving the mouse to be able to click on this button) will cause the timer to reset
                    }}
                  >
                    I'm still here
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <ScrollContext>
            <Main />
          </ScrollContext>
        </RepDataContextProvider>
      </ClientDataContextProvider>
    </UserContextProvider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("root")
);
