import axios from "axios";
import * as helper from "./serviceHelper";
import { v2Request } from "../helpers/request";
import { getProfile } from "./profileServices";

const { REACT_APP_API_URL, REACT_APP_V2_API_ENDPOINT } = process.env;
const endpoint = REACT_APP_API_URL + "/user"; //production

const registerRep = (payload) => {
  return v2Request("/users/register/rep", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const registerClient = (payload) => {
  return v2Request("/users/register/client", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const getCreateCustomer = () => {
  return v2Request("/payments/customer");
};

const createSubscription = (payload) => {
  return v2Request("/payments/subscriptions", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const subscribeAgain = (payload) => {
  return v2Request("/payments/subscribe-again", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const cancelSubscription = () => {
  return v2Request("/payments/subscriptions", { method: "DELETE" });
};

const getAppStatus = () => {
  return v2Request("/status");
};

const getCurrent = () => {
  return v2Request("/users/current");
};

const getFeatureFlags = () => {
  return v2Request("/config/feature-flags");
};

const getReleaseDates = () => {
  return v2Request("/config/release-dates");
};

const getConfig = () => {
  return v2Request("/config");
};

const UpdatePaymentMethod = (payload) => {
  return v2Request("/payments/update", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

const getCurrentPaymentMethod = () => {
  return v2Request("/payments/current-method");
};

const getCustomerInvoices = () => {
  return v2Request("/payments/invoices");
};

const login = (payload) => {
  return v2Request("/users/login", {
    body: JSON.stringify(payload),
    method: "POST",
  });
};

const referral = (payload) => {
  // NOTE: Not currently in general use as part of the application
  const config = {
    method: "POST",
    url: endpoint + "/referral",
    withCredentials: true,
    data: payload,
    crossdomain: true,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

const logOut = () => {
  return v2Request("/users/logout", { method: "POST" });
};

const selectById = (id) => {
  return getProfile();
};

const selectRegistratinNameById = (id) => {
  return getProfile();
};

const lookupUsers = async (data) => {
  return v2Request("/users/existing-users", {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const inviteUsers = async (data) => {
  return v2Request("/users/invite-users", {
    method: "POST",
    body: JSON.stringify(data),
  });
};

const getUsersAwaitingApproval = async () => {
  return v2Request("/users/awaiting-approval");
};

const getUserProfileData = async (userId) => {
  return v2Request(`/users/profile/${userId}`);
};

const approveUser = async (userId) => {
  return v2Request(`/users/approve/${userId}`);
};

const getNotificationsSettings = () => {
  return v2Request("/users/notifications-settings");
};

const updateNotificationsSettings = (data) => {
  return v2Request("/users/notifications-settings", {
    method: "PUT",
    body: JSON.stringify(data),
  });
};

const getUnreadChats = () => {
  return v2Request("/chats/unread");
};

const getNewAnnouncements = () => {
  return v2Request("/announcements");
};

export {
  getCurrent,
  selectRegistratinNameById,
  selectById,
  login,
  referral,
  registerRep,
  registerClient,
  logOut,
  getCreateCustomer,
  createSubscription,
  subscribeAgain,
  cancelSubscription,
  UpdatePaymentMethod,
  getCurrentPaymentMethod,
  getCustomerInvoices,
  lookupUsers,
  inviteUsers,
  getNotificationsSettings,
  updateNotificationsSettings,
  getUnreadChats,
  getNewAnnouncements,
  getFeatureFlags,
  getUsersAwaitingApproval,
  approveUser,
  getUserProfileData,
  getReleaseDates,
  getConfig,
  getAppStatus
};
